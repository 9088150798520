.App {
  --padding: 5rem;
  height: calc(100vh - var(--padding) * 2);
  width: calc(100vw - var(--padding) * 2);
  text-align: center;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: var(--padding);
  /* background-color: #006fff; */
}

.select-container{
  display: flex;
  flex-direction: column;
  gap: .5rem;
  align-items: center;
  width: fit-content;
  height: fit-content;
  /* background-color: grey; */
  padding: 4rem;
  border-radius: 1rem;
}

.select-box {
  min-width: 350px;
}

.select-label {
  color: black;
}